import React from "react";
import { Stack, Typography } from "@mui/material";

import ImgCard from "../../cards/ImgCard";
import RoundedButton from "../../elements/RoundedButton";
import { TitleComponents } from "../../titleComponent/TitleComponents";
import ContainerLayout from "../../layouts/ContainerLayout";
import { useNavigate } from "react-router-dom";

export default function ProgramTab() {
    const navigate = useNavigate();

    const goToMusicGoGoDetail = () => {
        navigate("/musicgogo");
    };

    const goToMusicGoGo = () => {
        window.open("https://musicgogo.co.kr/enroll/oneday");
    };

    const goToPianoGoGoDetail = () => {
        navigate("/pianogogo");
    };

    const goToPianoGoGo = () => {
        window.open("https://musicgogo.co.kr/enroll/pianogogo");
    };

    return (
        <>
            {/* 뮤직고고 */}
            <ContainerLayout>
                <Stack
                    direction={{ xs: "column", md: "row" }}
                    spacing={{ xs: 0, md: 10 }}
                    sx={{ mt: { xs: 6, md: 8 } }}>
                    <TitleComponents
                        sx={{
                            mb: { xs: 5.5, md: 0 },
                            alignItems: { xs: "center", md: "flex-start" },
                        }}>
                        <TitleComponents.MainTitle>
                            <span>뮤직플레이</span>
                        </TitleComponents.MainTitle>
                        <TitleComponents.Description stackStyle={{ textAlign: { xs: "center", md: "start" } }}>
                            <span>다양한 표현법을 통해 음률영역의 깊이있는 이해와</span>
                            <span>음악적 감성 향상을 위한 교육 프로그램입니다</span>
                        </TitleComponents.Description>
                    </TitleComponents>
                    {/* ImgCard */}
                    <ImgCard
                        title="음악 체험 통합 놀이 교육!"
                        height="400px"
                        textAlign="center"
                        bodyColor="littleBand.musicgogoLight"
                        footerColor="constant.darkWhite"
                        smallImgTop="55px"
                        smallImgWidth="243px"
                        smallImgSrc="/images/littleband/musicgogo-logo.svg"
                        cardStyle={{ flex: 1 }}>
                        <span>음악적 사고력 개발!</span>
                        <span>창의성과 예술적 표현력 강화!</span>
                        <span>감각적인 학습 경험!</span>
                    </ImgCard>
                </Stack>
                {/* Buttons */}
                <Stack
                    direction={{ xs: "column", md: "row" }}
                    spacing={2}
                    sx={{ mt: 8, mb: 7.5, alignItems: "center", justifyContent: "center" }}>
                    <Stack>
                        <Typography variant="caption" sx={{ textAlign: "center" }}>
                            뮤직플레이에 대해 더 알고 싶다면?
                        </Typography>
                        <RoundedButton
                            onClick={goToMusicGoGoDetail}
                            size="large"
                            color="littleBand.main"
                            otherHoverColor="constant.white">
                            더 알아보기
                        </RoundedButton>
                    </Stack>
                    <Stack>
                        <Typography variant="caption" sx={{ textAlign: "center" }}>
                            우리아이 뮤직플레이 체험을 신청해보세요!
                        </Typography>
                        <RoundedButton onClick={goToMusicGoGo} variant="contained" size="large" color="littleBand.main">
                            체험신청
                        </RoundedButton>
                    </Stack>
                </Stack>
            </ContainerLayout>
            {/* 피아노고고 */}
            <ContainerLayout bgColor="constant.darkWhite">
                <Stack
                    direction={{ xs: "column", md: "row" }}
                    spacing={{ xs: 0, md: 10 }}
                    sx={{ mt: { xs: 6, md: 8 } }}>
                    <TitleComponents
                        sx={{
                            mb: { xs: 5.5, md: 0 },
                            alignItems: { xs: "center", md: "flex-start" },
                        }}>
                        <TitleComponents.MainTitle>
                            <span>피아노고고</span>
                        </TitleComponents.MainTitle>
                        <TitleComponents.Description stackStyle={{ textAlign: { xs: "center", md: "start" } }}>
                            <span>자기주도적 몰입(FLOW)과정을 통해</span>
                            <span>두뇌를 열어주는 통합형 레슨 프로그램입니다</span>
                        </TitleComponents.Description>
                    </TitleComponents>
                    {/* ImgCard */}
                    <ImgCard
                        title="음악 체험 통합 놀이 교육!"
                        height="400px"
                        textAlign="center"
                        bodyColor="littleBand.pianogogoLight"
                        smallImgTop="55px"
                        smallImgWidth="243px"
                        smallImgSrc="/images/littleband/pianogogo-logo.svg"
                        cardStyle={{ flex: 1 }}>
                        <span>음악적 사고력 개발!</span>
                        <span>창의성과 예술적 표현력 강화!</span>
                        <span>감각적인 학습 경험!</span>
                    </ImgCard>
                </Stack>
                {/* Buttons */}
                <Stack
                    direction={{ xs: "column", md: "row" }}
                    spacing={2}
                    sx={{ mt: 8, mb: 7.5, alignItems: "center", justifyContent: "center" }}>
                    <Stack>
                        <Typography variant="caption" sx={{ textAlign: "center" }}>
                            피아노고고에 대해 더 알고 싶다면?
                        </Typography>
                        <RoundedButton
                            onClick={goToPianoGoGoDetail}
                            size="large"
                            color="littleBand.main"
                            otherHoverColor="constant.white"
                            sx={{ backgroundColor: "constant.white" }}>
                            더 알아보기
                        </RoundedButton>
                    </Stack>
                    <Stack>
                        <Typography variant="caption" sx={{ textAlign: "center" }}>
                            우리아이 피아노고고 체험을 신청해보세요!
                        </Typography>
                        <RoundedButton onClick={goToPianoGoGo} variant="contained" size="large" color="littleBand.main">
                            체험신청
                        </RoundedButton>
                    </Stack>
                </Stack>
            </ContainerLayout>
        </>
    );
}
