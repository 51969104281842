import React from "react";
import { Stack, SwipeableDrawer, IconButton, Typography } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export default function RightDrawerProvider({
    menu, //
    open,
    setOpen,
    topBgColor,
    topHeight,
}) {
    const navigate = useNavigate();
    const toggleDrawer = () => {
        setOpen(!open);
    };
    return (
        <SwipeableDrawer
            anchor="right"
            open={Boolean(open)}
            onClose={toggleDrawer}
            onOpen={toggleDrawer}
            PaperProps={{
                sx: { width: "80%" },
            }}>
            <Stack
                role="right_menu"
                onKeyDown={toggleDrawer}
                bgcolor={topBgColor}
                sx={{ width: "100%", height: "100%" }}>
                {/* onClick={toggleDrawer} */}
                {/* 메뉴 아이콘 */}
                <Stack alignItems="flex-end">
                    <IconButton
                        size="large"
                        aria-label="메뉴"
                        aria-controls="메뉴"
                        aria-haspopup="true"
                        onClick={toggleDrawer}
                        sx={{
                            mr: "13px",
                            color: "#343434",
                            pt: topHeight,
                            width: "35px",
                            height: "35px",
                        }}>
                        <CloseRounded sx={{ color: "white", fontSize: 30 }} />
                    </IconButton>
                </Stack>

                {/* 메뉴 리스트 */}
                <Stack direction="column" alignItems="flex-end" spacing={4} sx={{ mt: "40px" }}>
                    {menu.map((me, index) => (
                        <Stack
                            key={me.id}
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                                if (me.link === "/franchise") {
                                    window.open("http://jueunedu.com", "가맹안내");
                                } else {
                                    navigate(me.link);
                                }
                                setOpen(false);
                            }}>
                            {me.level === 1 && (
                                <Typography
                                    sx={{
                                        mr: "17px",
                                        fontSize: "24px",
                                        color: "#f5f5f5",
                                        fontFamily: "Pretendard",
                                        fontWeight: "500",
                                        ":hover": {
                                            color:
                                                index === 1
                                                    ? "#3390ff"
                                                    : index == 2
                                                      ? "#ffa324"
                                                      : index == 0
                                                        ? "#fe3a54"
                                                        : "#00b83a",
                                            fontWeight: "800",
                                        },
                                    }}>
                                    {me.menu_name}
                                </Typography>
                            )}
                        </Stack>
                    ))}
                </Stack>

                {/* sub list */}
                <Stack direction="column" alignItems="flex-end" spacing={2} sx={{ mt: "70px" }}>
                    <a href="https://imuz.co.kr" target="_blank" rel="noreferrer" onClick={() => setOpen(false)}>
                        <Typography
                            sx={{
                                mr: "17px",
                                fontSize: "20px",
                                color: "#a0a0a0",
                                fontFamily: "Pretendard",
                                fontWeight: "500",
                                ":hover": {
                                    color: "white",
                                    fontWeight: "600",
                                },
                            }}>
                            아이뮤즈몰
                        </Typography>
                    </a>
                    <a href="https://pianogogo.co.kr" target="_blank" rel="noreferrer" onClick={() => setOpen(false)}>
                        <Typography
                            sx={{
                                mr: "17px",
                                fontSize: "20px",
                                color: "#a0a0a0",
                                fontFamily: "Pretendard",
                                fontWeight: "500",
                                ":hover": {
                                    color: "white",
                                    fontWeight: "600",
                                },
                            }}>
                            스마트피아노
                        </Typography>
                    </a>
                    <a href="https://musicgogo.co.kr" target="_blank" rel="noreferrer" onClick={() => setOpen(false)}>
                        <Typography
                            sx={{
                                mr: "17px",
                                fontSize: "20px",
                                color: "#a0a0a0",
                                fontFamily: "Pretendard",
                                fontWeight: "500",
                                ":hover": {
                                    color: "white",
                                    fontWeight: "600",
                                },
                            }}>
                            스마트뮤직플레이
                        </Typography>
                    </a>
                </Stack>
                {/* link footer */}
                <Stack
                    sx={{
                        height: "85px",
                        width: "100%",
                        position: "fixed",
                        bottom: 0,
                        borderTop: "1px solid #757575",
                    }}>
                    <Stack
                        direction="row"
                        sx={{
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            width: "80%",
                            // border: "2px solid red",
                            height: "85px",
                            img: {
                                "&:hover": {
                                    cursor: "pointer",
                                },
                            },
                        }}>
                        {links.map(l => (
                            <a key={l.alt} href={`${l.link}`} target="_blank" rel="noreferrer">
                                <img src={`/images/footer/${l.img}`} alt={`${l.alt}`} />
                            </a>
                        ))}
                    </Stack>
                </Stack>
            </Stack>
        </SwipeableDrawer>
    );
}

const links = [
    {
        img: "youtube-icon.svg",
        link: "https://www.youtube.com/@jueunedu",
        alt: "유튜브",
    },
    {
        img: "naver-icon.svg",
        link: "https://blog.naver.com/jueun20100",
        alt: "네이버블로그",
    },
    {
        img: "instagram-icon.svg",
        link: "https://www.instagram.com/ikidsband_ikidsart_jueun/",
        alt: "인스타그램",
    },
    {
        img: "kakaotalk-icon.svg",
        link: "https://pf.kakao.com/_xktTxoM",
        alt: "카카오톡채널",
    },
];
