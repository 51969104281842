import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Container, Stack, Typography } from "@mui/material";
import { TitleComponents } from "../titleComponent/TitleComponents.js";
import theme from "../../styles/theme";
import NewsDetailBtn from "../elements/NewsDetailBtn.js";
import Footer from "./Footer.js";
import ScrollToTop from "../elements/ScrollToTop";
import ContainerLayout from "./ContainerLayout";

export default function NoticDetail({ isNews = true, brand, info }) {
    const navigate = useNavigate();

    const brandColor =
        brand === "littleband" ? "littleBand.main" : brand === "littleartists" ? "littleArtist.main" : "primary.main";

    // 최초 진입 시 스크롤 최상단으로 이동
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Stack>
            <ContainerLayout
                sx={{
                    position: "fixed",
                    top: 0,
                    width: "100%",
                    textAlign: "center",
                    py: 3,
                    borderBottom: "1px solid",
                    borderColor: "constant.gray",
                }}>
                <Stack direction="row" sx={{ alignItems: "center", justifyContent: "space-between" }}>
                    {/* 버튼 */}
                    <NewsDetailBtn onClick={() => navigate(-1)} />
                    {/* title */}
                    <TitleComponents>
                        <TitleComponents.Subtitle typoStyle={{ color: brandColor }}>
                            {isNews ? "소식&뉴스" : "갤러리"}
                        </TitleComponents.Subtitle>
                    </TitleComponents>
                    <Stack sx={{ width: 40 }} />
                </Stack>
            </ContainerLayout>
            <Container maxWidth="xl" sx={{ mt: 11, pl: { xs: 0 }, pr: { xs: 0 } }}>
                <Stack alignItems="center" sx={{ p: "29px 16px 20px" }}>
                    {/* 뉴스 헤더 */}
                    <Stack
                        justifyContent="center"
                        alignItems="center"
                        sx={{ width: "100%", height: "109px", bgcolor: "#f5f5f5", p: "16px 47px 16px 16px" }}>
                        <TitleComponents>
                            <TitleComponents.MainTitle
                                typoStyle={{
                                    color: "black",
                                    fontSize: "18px",
                                    letterSpacing: "-0.14px",
                                    lineHeight: "1.56",
                                }}>
                                {info.title}
                            </TitleComponents.MainTitle>
                        </TitleComponents>
                    </Stack>
                    <Stack
                        flexDirection="row"
                        justifyContent="space-between"
                        sx={{
                            width: "100%",
                            p: "8.8px 0",
                            color: "#a0a0a0",
                            borderBottom: "1px solid",
                            borderColor: "constant.gray",
                        }}>
                        <Typography>{isNews ? info.type : "갤러리"}</Typography>
                        <Typography>{info.date}</Typography>
                    </Stack>
                </Stack>
                {/* 뉴스 or 갤러리 컨텐츠 */}
                {info?.des?.length > 0 && (
                    <Stack alignItems="center" spacing={4} sx={{ height: "100%", width: "100%", mb: 20 }}>
                        {info.des.map((co, index) => (
                            <Stack key={index} alignItems="center" sx={{ width: "100%" }}>
                                {co.text && (
                                    <Stack mx={{ xs: 2, sm: 4, md: 6, lg: 10 }}>
                                        <Typography
                                            sx={{ whiteSpace: "pre-line", textAlign: "center" }}
                                            variant="body1">
                                            {co.text.split(" : ")[0] === "원문보기" ? (
                                                <span>
                                                    원분보기 :{" "}
                                                    <a href={co.text.split(" : ")[1]}>{co.text.split(" : ")[1]}</a>
                                                </span>
                                            ) : (
                                                co.text
                                            )}
                                        </Typography>
                                    </Stack>
                                )}
                                {co.image && (
                                    <Stack maxWidth="md">
                                        <img src={co.image} alt={`new image ${index}`} />
                                    </Stack>
                                )}
                                {co.line && (
                                    <Stack
                                        sx={{
                                            borderTop: `1px solid ${theme.palette.constant.gray}`,
                                            width: "100%",
                                            height: "10px",
                                            maxWidth: co.line,
                                        }}
                                    />
                                )}
                            </Stack>
                        ))}
                    </Stack>
                )}
            </Container>
            <ScrollToTop />
            <Footer />
        </Stack>
    );
}
