import React from "react";
import { Grid } from "@mui/material";
import ImgHeaderCard from "../../cards/ImgHeaderCard";
import ContainerLayout from "../../layouts/ContainerLayout";

export default function PianoGogoTool() {
    return (
        <ContainerLayout sx={{ py: 8 }}>
            <Grid container spacing={2}>
                {toolArr.map(item => (
                    <Grid key={item.title} item xs={12} md={6} lg={3}>
                        <ImgHeaderCard
                            headerTitle={item.title}
                            headerBgColor="littleBand.pianogogo"
                            headerFontColor="constant.white"
                            imgSrc={item.imgSrc}>
                            {item.description}
                        </ImgHeaderCard>
                    </Grid>
                ))}
            </Grid>
        </ContainerLayout>
    );
}

const toolArr = [
    {
        imgSrc: "/images/littleband/piano-gogo/picture-5.png",
        title: "레슨교재",
        description: (
            <>
                <span>리듬, 교구, 음악이론 교재와 연계되어</span>
                <span>체계화 되고 차별화 된 피아노 레슨이 가능합니다</span>
            </>
        ),
    },
    {
        imgSrc: "/images/littleband/piano-gogo/picture-6.png",
        title: "리듬악기",
        description: (
            <>
                <span>양손을 사용하여 연주하는 리듬악기는 리듬감/음감,</span>
                <span>빠르기/셈여림 등의 음악적 감각을 증진시킵니다</span>
            </>
        ),
    },
    {
        imgSrc: "/images/littleband/piano-gogo/picture-7.png",
        title: "음악전문교구",
        description: (
            <>
                <span>특허 검증 교구를 사용함으로써 소근육을 발달시키며</span>
                <span>피아노 레슨의 효과를 높여줍니다</span>
            </>
        ),
    },
    {
        imgSrc: "/images/littleband/piano-gogo/picture-8.png",
        title: "스마트피아노",
        description: (
            <>
                <span>어린음악대의 연구개발로 완성된 온라인 피아노 연습</span>
                <span>프로그램으로, 이론/피아노연습/평가 등 다양한 컨텐츠를</span>
                <span>활용하여 수업 효율을 향상시킵니다</span>
            </>
        ),
    },
];
