import { Divider, Stack, Typography } from "@mui/material";
import { TitleComponents } from "../titleComponent/TitleComponents";
import React from "react";
import { TrapezoidCard } from "../cards/TrapezoidCard";
import ImgSlickCard from "../cards/ImgSlickCard";
import ImgCard from "../cards/ImgCard";
import ImgTabCard from "../cards/ImgTabCard";
import ContainerLayout from "../layouts/ContainerLayout";
import ImuzmallLinkButton from "../elements/ImuzmallLinkButton";
import ImgHeaderCard from "../cards/ImgHeaderCard";

function BrandIntro1() {
    return (
        <ContainerLayout>
            <Stack sx={{ alignItems: "center", p: "64px 0px" }}>
                <TitleComponents sx={{ alignItems: "center" }}>
                    <TitleComponents.MainTitle
                        stackStyle={{ textAlign: "center", md: "start" }}
                        typoStyle={{ color: "littleBand.main" }}>
                        <span>우리들은</span>
                        <span>어린음악대!</span>
                    </TitleComponents.MainTitle>
                    <TitleComponents.Description stackStyle={{ textAlign: "center" }}>
                        <span>악보대로만 연주하는 것이 올바른 음악교육일까요?</span>
                        <span>악기 연주만으로 음악적 감성이 풍부해질까요?</span>
                        <span>아름다운 소통, ‘음악’을 특별한 어린음악대에서 만나보세요.</span>
                    </TitleComponents.Description>
                </TitleComponents>
                <Stack sx={{ mt: 5.5, width: { xs: "100%", md: "60%" } }}>
                    <LittlebandWithLogo />
                </Stack>
            </Stack>
        </ContainerLayout>
    );
}

export function LittlebandWithLogo() {
    return (
        <ImgCard
            height="360px"
            descFontSize="18px"
            bodyColor="#dbecff"
            smallImgSrc="/images/littleband/brandIntro/brandIntro-logo.png" // .svg => .png
            smallImgWidth="243px"
            smallImgTop={{ xs: "30px", md: "60px" }}
            smallImgWidthForMd="350px">
            <span>독점 교재 및 특허받은 교구를 활용하여 좌뇌와 우뇌를 균형있게 종합 발달시키는</span>
            <span>국내 최초 두뇌밸런스 음악교육 프랜차이즈입니다.</span>
        </ImgCard>
    );
}

function BrandIntro2() {
    return (
        <ContainerLayout bgColor="constant.darkWhite" bgUrl="/images/littleband/brandIntro/why어린음악대bg.webp">
            <Stack sx={{ p: "64px 0px" }}>
                <Stack>
                    <TitleComponents>
                        <TitleComponents.MainTitle>
                            <span>Why?</span>
                            <span>어린음악대인가?</span>
                        </TitleComponents.MainTitle>
                    </TitleComponents>
                </Stack>
                <Stack alignItems="center" sx={{ width: { xs: "100%", md: "93%", lg: "100%" }, mt: "44px" }}>
                    <Stack
                        justifyContent={{ xs: "center", lg: "space-between" }}
                        flexDirection={{ xs: "row" }}
                        sx={{ flexWrap: "wrap" }}>
                        <TrapezoidCard
                            number={1}
                            coloredTitle="블랜디드"
                            title="음악교육"
                            title2="프로그램"
                            brandName="littleBand">
                            <span>온라인과 오프라인 수업을</span>
                            <span>넘나들며,알파세대를 위한</span>
                            <span>스마트한 피아노 교육을 지향합니다.</span>
                        </TrapezoidCard>
                        <TrapezoidCard
                            number={2}
                            title="체계적인"
                            coloredTitle2="학원 관리"
                            title2="시스템"
                            bodyColor="#edf6ff"
                            brandName="littleBand">
                            <span>온라인 학원 관리 시스템 으로 </span>
                            <span>원생관리, 진도관리, 학습리포트 등을 체계적으로 관리합니다.</span>
                        </TrapezoidCard>
                        <TrapezoidCard
                            number={3}
                            coloredTitle="음악 몰입"
                            title="교육환경"
                            bodyColor="#edf6ff"
                            brandName="littleBand">
                            <span>독점교재, 특허교구, 통합 놀이</span>
                            <span>교육 온라인 피아노 연습 등을</span>
                            <span>통한 다양한 교육방법을 제시합니다</span>
                        </TrapezoidCard>
                        <TrapezoidCard
                            number={4}
                            title="차별화된"
                            coloredTitle2="학원 경영"
                            title2="노하우"
                            brandName="littleBand">
                            <span>온오프라인 융합수업에</span>
                            <span>차별화 된 콘텐츠를 바탕으로</span>
                            <span>한 최적의 경영 특급 노하우를 제공합니다</span>
                        </TrapezoidCard>
                    </Stack>
                </Stack>
            </Stack>
        </ContainerLayout>
    );
}

function BrandIntro3() {
    return (
        <ContainerLayout>
            <Stack sx={{ p: "64px 0px" }}>
                <TitleComponents.MainTitle
                    stackStyle={{ textAlign: "center" }}
                    typoStyle={{ color: "littleBand.main" }}>
                    <span>음악학원도</span>
                    <span>달라져야합니다</span>
                </TitleComponents.MainTitle>
                <TitleComponents.Description stackStyle={{ textAlign: "center" }}>
                    <span>신개념 음악체험 통합놀이 교육 어린음악대</span>
                </TitleComponents.Description>
                <Stack sx={{ mt: "44px", width: { md: "70%" }, mx: { md: "auto" } }}>
                    {/* <ImgCard
                        smallImgSrc="/images/littleband/brandIntro/인재양성2.webp"
                        smallImgWidth="400px"
                        smallImgWidthForMd="600px"
                        smallImgTop="26px">
                        두뇌를 깨우는 힘을 길러주는 것이 중요합니다
                    </ImgCard> */}
                    <Stack alignItems="center">
                        <ImgHeaderCard
                            cardStyle={{ width: { xs: "90%", md: "600px", lg: "600px" } }}
                            headerTitle="창의융합형 인재양성"
                            headerFontColor="white"
                            headerBgColor="#3390ff"
                            imgStyle={{ width: "400px", margin: "auto", paddingTop: "26x" }}
                            imgSrc="/images/littleband/brandIntro/인재양성2.webp">
                            두뇌를 깨우는 힘을 길러주는 것이 중요합니다!
                        </ImgHeaderCard>
                    </Stack>
                </Stack>
            </Stack>
        </ContainerLayout>
    );
}

export function BrandIntro4() {
    return (
        <ContainerLayout bgColor="constant.posterBg">
            <Stack
                justifyContent={{ md: "space-between" }}
                flexDirection={{ xs: "column", md: "row" }}
                sx={{ p: "64px 0px" }}>
                <Stack sx={{ pt: { md: 5 } }}>
                    <TitleComponents>
                        <TitleComponents.Subtitle typoStyle={{ color: "littleBand.main" }}>
                            음악학원도 달라져야 합니다
                        </TitleComponents.Subtitle>
                        <TitleComponents.MainTitle>
                            <span>두뇌밸런스</span>
                            <span>음악교육</span>
                            <span>프로그램</span>
                        </TitleComponents.MainTitle>
                        <TitleComponents.Description>
                            <span>우리 아이에게 필요한</span>
                            <span>올바른 교육은 무엇일까요?</span>
                        </TitleComponents.Description>
                    </TitleComponents>
                </Stack>
                <Stack sx={{ mt: "44px", width: { md: "60%" } }}>
                    <ImgTabCard tabList={tabList} />
                </Stack>
            </Stack>
        </ContainerLayout>
    );
}

export function BrandIntro5() {
    return (
        <ContainerLayout>
            <Stack
                flexDirection={{ md: "row-reverse" }}
                justifyContent={{ md: "space-between" }}
                sx={{ p: "64px 0px" }}>
                <Stack sx={{ pt: { md: 5 } }}>
                    <TitleComponents>
                        <TitleComponents.Subtitle
                            stackStyle={{ textAlign: "end" }}
                            typoStyle={{ color: "littleBand.main" }}>
                            스마트한 음악교육의 시작
                        </TitleComponents.Subtitle>
                        <TitleComponents.MainTitle stackStyle={{ textAlign: "end" }}>
                            <span>스마트</span>
                            <span>뮤직클래스</span>
                        </TitleComponents.MainTitle>
                        <TitleComponents.Description stackStyle={{ textAlign: "end" }}>
                            <span>다양한 악기와 음악교구를 비치하여 음률 수업 특강을</span>
                            <span>즐겁게 체험 할 수 있도록 기획, 설계된 독창적인 체험공간</span>
                        </TitleComponents.Description>
                    </TitleComponents>
                </Stack>
                <Stack sx={{ mt: "44px", width: { md: "60%" } }}>
                    <ImgSlickCard
                        sliderArr={[
                            { imgSrc: "/images/littleband/brandIntro/smart-classroom/01.jpg" },
                            { imgSrc: "/images/littleband/brandIntro/smart-classroom/02.jpg" },
                            { imgSrc: "/images/littleband/brandIntro/smart-classroom/03.jpg" },
                            { imgSrc: "/images/littleband/brandIntro/smart-classroom/04.jpg" },
                            { imgSrc: "/images/littleband/brandIntro/smart-classroom/05.jpg" },
                        ]}
                        imgStyle={{ width: "100%", height: "100%", objectFit: "contain" }}
                    />
                </Stack>
            </Stack>
        </ContainerLayout>
    );
}

export function BrandIntro6() {
    return (
        <ContainerLayout bgColor="constant.darkWhite">
            <Stack flexDirection={{ md: "row" }} justifyContent={{ md: "space-between" }} sx={{ p: "64px 0px" }}>
                <Stack sx={{ mt: { md: 5 } }}>
                    <TitleComponents>
                        <TitleComponents.Subtitle typoStyle={{ color: "littleBand.main" }}>
                            스마트한 음악교육의 시작
                        </TitleComponents.Subtitle>
                        <TitleComponents.MainTitle>
                            <span>전문 음악교구,</span>
                            <span>교재 활용 수업</span>
                        </TitleComponents.MainTitle>
                        <TitleComponents.Description>
                            <span>어린음악대만의 독점교재 및 교구로</span>
                            <span>다양한 시각으로 음악교육에 접근합니다</span>
                        </TitleComponents.Description>
                    </TitleComponents>
                </Stack>
                <Stack sx={{ mt: "44px", width: { md: "60%" } }}>
                    <ImgSlickCard
                        sliderArr={[
                            { imgSrc: "/images/littleband/brandIntro/tools/01.jpg" },
                            { imgSrc: "/images/littleband/brandIntro/tools/02.jpg" },
                            { imgSrc: "/images/littleband/brandIntro/tools/03.jpg" },
                            { imgSrc: "/images/littleband/brandIntro/tools/04.jpg" },
                            { imgSrc: "/images/littleband/brandIntro/tools/05.jpg" },
                        ]}
                        imgStyle={{ width: "100%", height: "100%", objectFit: "contain" }}
                    />
                     
                </Stack>
            </Stack>
        </ContainerLayout>
    );
}

export function BrandIntro7() {
    return (
        <ContainerLayout>
            <Stack
                flexDirection={{ md: "row-reverse" }}
                justifyContent={{ md: "space-between" }}
                sx={{ p: "64px 0px" }}>
                <Stack sx={{ pt: { md: 5 } }}>
                    <TitleComponents>
                        <TitleComponents.Subtitle
                            stackStyle={{ textAlign: "end" }}
                            typoStyle={{ color: "littleBand.main" }}>
                            스마트한 음악교육의 시작
                        </TitleComponents.Subtitle>
                        <TitleComponents.MainTitle stackStyle={{ textAlign: "end" }}>
                            <span>1:1담임제</span>
                            <span>심층레슨 시스템</span>
                        </TitleComponents.MainTitle>
                        <TitleComponents.Description stackStyle={{ textAlign: "end" }}>
                            <span>체계적인 학원관리 시스템으로 담임선생님이</span>
                            <span>아이의 학습 및 생활을 밀착 관리합니다</span>
                        </TitleComponents.Description>
                    </TitleComponents>
                </Stack>
                <Stack sx={{ mt: "44px", width: { md: "60%" } }}>
                    <ImgSlickCard
                        sliderArr={[
                            { imgSrc: "/images/littleband/brandIntro/teachers/01.jpg" },
                            { imgSrc: "/images/littleband/brandIntro/teachers/02.jpg" },
                            { imgSrc: "/images/littleband/brandIntro/teachers/03.jpg" },
                        ]}
                        imgStyle={{ width: "100%", height: "100%", objectFit: "contain" }}
                    />
                     
                </Stack>
            </Stack>
        </ContainerLayout>
    );
}

function BrandIntro8() {
    return (
        <ContainerLayout bgColor="constant.darkWhite">
            <Stack sx={{ p: "64px 0px" }}>
                <TitleComponents sx={{ alignItems: "center" }}>
                    <TitleComponents.MainTitle
                        stackStyle={{ textAlign: "center" }}
                        typoStyle={{ color: "text.primary" }}>
                        <span>아이뮤즈몰</span>
                    </TitleComponents.MainTitle>
                    <TitleComponents.Description stackStyle={{ textAlign: "center" }}>
                        <span>아이뮤즈몰에서도 예술교육 NO.1</span>
                        <span>어린음악대를 만날 수 있습니다</span>
                    </TitleComponents.Description>
                </TitleComponents>
                <Stack alignItems={{ md: "center" }}>
                    <Stack sx={{ mt: "44px", width: { md: "50%" } }}>
                        <ImgCard
                            smallImgSrc="/images/littleband/brandIntro/아이뮤즈몰.webp"
                            bodyColor="#ffffff"
                            height="230px"
                            smallImgWidth="250px"
                            smallImgTop="70px"
                        />
                    </Stack>
                </Stack>
                <Stack alignItems="center" sx={{ m: "40px 64px 46px" }}>
                    <ImuzmallLinkButton />
                </Stack>
            </Stack>
        </ContainerLayout>
    );
}

export function BrandIntro() {
    return (
        <Stack>
            <BrandIntro1 />
            <Divider />
            <BrandIntro2 />
            <Divider />
            <BrandIntro3 />
            <Divider />
            <BrandIntro4 />
            <Divider />
            <BrandIntro5 />
            <Divider />
            <BrandIntro6 />
            <Divider />
            <BrandIntro7 />
            <Divider />
            <BrandIntro8 />
        </Stack>
    );
}

const tabList = [
    {
        tabTitle: "피아노고고",
        tabImg: "/images/brand/brands/leftbrain.png",
        tabDescription: (
            <>
                <Typography variant="h3" sx={{ mb: 1 }}>
                    좌뇌발달 "피아노고고"
                </Typography>
                <Typography variant="subtitle3" sx={{ fontWeight: "bold" }}>
                    독점교재·특허교구를 활용한
                </Typography>
                <Typography variant="body1" sx={{ mt: 0.5, span: { display: "block" } }}>
                    <span>뮤직 플로우 피아노 교육 프로그램</span>
                </Typography>
            </>
        ),
        activeBgColor: "littleBand.pianogogo",
    },
    {
        tabTitle: "뮤직플레이",
        // /images/littleband/brandIntro/우뇌.webp 수현씨한테 수정된 우뇌 이미지 받으면 적용
        tabImg: "/images/brand/brands/rightbrain.png",
        tabDescription: (
            <>
                <Typography variant="h3" sx={{ mb: 1 }}>
                    우뇌발달 "뮤직플레이"
                </Typography>
                <Typography variant="subtitle3" sx={{ fontWeight: "bold" }}>
                    악기연주, 신체활동, 음악이론, 감상&표현
                </Typography>
                <Typography variant="body1" sx={{ mt: 0.5, span: { display: "block" } }}>
                    <span>을 포함한 음악교육의 전 영역</span>
                </Typography>
            </>
        ),
        activeBgColor: "littleBand.musicgogo",
    },
];
