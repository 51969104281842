import React from "react";
import ContainerLayout from "../../layouts/ContainerLayout";
import { TitleComponents } from "../../titleComponent/TitleComponents";
import { Stack, Typography } from "@mui/material";

export default function JueunTab() {
    return (
        <>
            {/* 인사말 */}
            <ContainerLayout sx={{ pb: 8 }}>
                <Stack
                    spacing={{ xs: 2, md: 4 }}
                    sx={{ alignItems: "center", mt: { xs: 6, md: 8 }, px: { xs: 0, md: 15 } }}>
                    <TitleComponents.MainTitle
                        stackStyle={{ mt: 8 }}
                        typoStyle={{ color: "primary.main", textAlign: "center" }}>
                        주은교육은?
                    </TitleComponents.MainTitle>
                    <Stack
                        sx={{
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "14px",
                            width: "100%",
                            //border: "1px solid red",
                        }}>
                        <Stack sx={{ maxWidth: "146px" }}>
                            <img src="/images/jueun-edu/icon_ah.png" alt="아르떼하이" />
                        </Stack>
                        <Stack sx={{ maxWidth: "146px" }}>
                            <img src="/images/jueun-edu/icon_as.png" alt="아르떼씨" />
                        </Stack>
                        <Stack sx={{ maxWidth: "146px" }}>
                            <img src="/images/jueun-edu/icon_ak.png" alt="아르떼케이" />
                        </Stack>
                        <Stack sx={{ maxWidth: "146px" }}>
                            <img src="/images/jueun-edu/icon_aj.png" alt="아르떼지" />
                        </Stack>
                    </Stack>
                    <Stack sx={{ flexDirection: "column", alignItems: "center", pt: 1 }}>
                        <Typography sx={{ fontFamily: "S-CoreDream4", fontSize: { xs: "14px", md: "22px" } }}>
                            오감 놀이 예술 교육을 통해
                        </Typography>
                        <Typography sx={{ fontFamily: "S-CoreDream4", fontSize: { xs: "14px", md: "22px" } }}>
                            아이들이 스스로 놀이와 학습을 동시에 즐길 수 있는
                        </Typography>
                        <Typography sx={{ fontFamily: "S-CoreDream4", fontSize: { xs: "14px", md: "22px" } }}>
                            재미있고 바른 교육을 지향합니다
                        </Typography>
                    </Stack>
                </Stack>
                <Stack sx={{ position: "relative" }}>
                    <Stack
                        sx={{
                            display: { xs: "none", md: "flex" },
                            position: "absolute",
                            bottom: { md: "-200px", xl: "-150px" },
                            right: 0,
                            width: { md: "250px", xl: "353px" },
                        }}>
                        <img src="/images/jueun-edu/child.png" alt="child" />
                    </Stack>

                    <Stack
                        sx={{
                            display: { xs: "flex", md: "none" },
                            width: { xs: "250px", sm: "353px" },
                        }}>
                        <img src="/images/jueun-edu/child.png" alt="child" />
                    </Stack>
                </Stack>
            </ContainerLayout>
            <Stack
                sx={{
                    display: { xs: "none", md: "flex" },
                    height: 200,
                }}
            />

            {/* 사업분야 PART */}
            <Stack
                sx={{
                    flexDirection: { xs: "column", md: "row" },
                    bgcolor: "#eee",
                    width: "100%",
                }}>
                <Stack
                    sx={{
                        //border: "1px red solid",
                        width: { xs: "100%", md: "40%" },
                        flexDirection: "row",
                        justifyContent: "center",
                        pt: "85px",
                    }}>
                    <Stack>
                        <Typography
                            sx={{
                                fontFamily: "S-CoreDream7",
                                fontSize: { xs: "30px", sm: "40px", md: "50px" },
                                color: "#000",
                            }}>
                            사업분야
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: "S-CoreDream4",
                                fontSize: { xs: "12px", sm: "16px", md: "20px" },
                                color: "#000",
                            }}>
                            아르떼가 만든 특별한 배움
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: "S-CoreDream4",
                                fontSize: { xs: "12px", sm: "16px", md: "20px" },
                                color: "#000",
                            }}>
                            교육의 새로운 가능성을 엽니다
                        </Typography>
                    </Stack>
                </Stack>
                <Stack flex={1} sx={{ py: "74px" }}>
                    <Stack
                        sx={{
                            mr: { xs: 0, md: "20%" },
                            flexDirection: { xs: "column", xl: "row" },
                        }}>
                        <ImageCard
                            index={1}
                            titles={["아르떼하이", "ARTE-HI", "음악 미술 학원 가맹사업"]}
                            desc={[
                                "어린음악대 어린화가들은 전국 300여개의",
                                "국내 최대 음악 미술 학원 프랜차이즈로",
                                "독창적인 커리큘럼, 독점 교재&교구를",
                                "공급합니다.",
                            ]}
                        />
                        <ImageCard
                            index={2}
                            titles={["아르떼씨", "ARTE-SI", "시니어 사업"]}
                            desc={[
                                "총 4가지로 구성된 음악·미술 프로그램을",
                                "시니어에 맞게 개발하여 사회 동참 능력을",
                                "향상시키는 프로그램을 제공합니다.",
                            ]}
                        />
                    </Stack>
                    <Stack
                        sx={{
                            mr: { xs: 0, md: "20%" },
                            flexDirection: { xs: "column", xl: "row" },
                        }}>
                        <ImageCard
                            index={3}
                            titles={["아르떼케이", "ARTE-K", "스마트 프로그램 수출"]}
                            desc={[
                                "스마트 프로그램을 해외로 수출하는",
                                "사업으로 전 세계 각국에 교육 프로그램을",
                                "보급합니다.",
                            ]}
                        />
                        <ImageCard
                            index={4}
                            titles={["아르떼지", "ARTE-JI", "스마트 프로그램 공급사업"]}
                            desc={[
                                "주은교육만의 독자적인 프로그램으로",
                                "홈스쿨링, 교습소 등 일반 교육기관에서도",
                                "활용할 수 있게 공급합니다.",
                            ]}
                        />
                    </Stack>
                </Stack>
            </Stack>

            {/* 프로그램 소개 PART */}
            <Stack
                sx={{
                    flexDirection: { xs: "column", md: "row" },
                    bgcolor: "#fff",
                    width: "100%",
                }}>
                {/* [1]left title */}
                <Stack
                    sx={{
                        width: { xs: "100%", md: "40%" },
                        flexDirection: "row",
                        justifyContent: "center",
                        pt: "80px",
                    }}>
                    {/* Left Title */}
                    <Stack sx={{ flexDirection: "column", alignItems: "center" }}>
                        <Stack sx={{ width: { xs: 300, md: "100%" } }}>
                            <Typography
                                sx={{
                                    fontFamily: "S-CoreDream7",
                                    fontSize: { xs: "30px", sm: "40px", md: "50px" },
                                    color: "#000",
                                }}>
                                프로그램 소개
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: "S-CoreDream4",
                                    fontSize: { xs: "12px", sm: "16px", md: "20px" },
                                    color: "#000",
                                }}>
                                아르떼의 특별함!
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: "S-CoreDream4",
                                    fontSize: { xs: "12px", sm: "16px", md: "20px" },
                                    color: "#000",
                                }}>
                                스마트 예술 프로그램으로
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: "S-CoreDream4",
                                    fontSize: { xs: "12px", sm: "16px", md: "20px" },
                                    color: "#000",
                                }}>
                                배우는 예술의 세계
                            </Typography>
                        </Stack>
                        <Stack
                            sx={{
                                // border: "2px green solid",
                                flexDirection: "row",
                                justifyContent: "center",
                                width: { md: 300 },
                                mt: "25px",
                                px: { xs: 3, md: 0 },
                            }}>
                            <img src="/images/jueun-edu/logo_smartpiano.png" alt="1" width={"23%"} />
                            <img src="/images/jueun-edu/logo_smartdrum.png" alt="2" width={"23%"} />
                            <img src="/images/jueun-edu/logo_smartmusicplay.png" alt="3" width={"28%"} />
                            <img src="/images/jueun-edu/logo_smartart.png" alt="4" width={"26%"} />
                        </Stack>
                    </Stack>
                </Stack>
                {/* [2] right content */}
                <Stack flex={1} sx={{ py: "74px" }}>
                    <Stack
                        sx={{
                            mr: { xs: 0, md: "20%" },
                            flexDirection: { xs: "column", xl: "row" },
                        }}>
                        <FourImagesCard
                            index={1}
                            titles={["레슨교재", "스마트피아노", "리듬악기", "음악전문교구"]}
                            desc={[
                                ["전문 연구진이", "개발한 '어린음악대'", "독점레슨교재"],
                                ["피아노 레슨의", "신개념 패러다임", "스마트피아노"],
                                ["음악적 표현력을", "높이는 리듬악기"],
                                ["음악교구 최초", "특허인증을 받은", "음악전문교구"],
                            ]}
                            color="#3d57a5"
                            bgcolor="#eeeeef"
                        />
                        <FourImagesCard
                            index={2}
                            titles={["드럼 이론", "패턴 연습", "드럼 개인레슨", "드럼 게임고고"]}
                            desc={[
                                ["드럼의", "기초이론을 통해", "스스로 학습 가능"],
                                ["연주의 기본기를", "탄탄히 다지는", "패턴 연습"],
                                ["즉각적인", "피드백 제공으로", "메타인지 가능"],
                                ["아이들의 성취감 및", "흥미유발에 최적화된", "학습 게임"],
                            ]}
                            color="#fede46"
                            textColor="#40200f"
                            bgcolor="#eeeeef"
                        />
                    </Stack>
                    <Stack
                        sx={{
                            mr: { xs: 0, md: "20%" },
                            flexDirection: { xs: "column", xl: "row" },
                        }}>
                        <FourImagesCard
                            index={3}
                            titles={["연령별 맞춤 커리큘럼", "영상 컨텐츠", "1인 음악전문교구", "워크지"]}
                            desc={[
                                ["연령별 맞춤", "커리큘럼으로", "수업의 집중도 UP!"],
                                ["전문교사의", "영상 컨텐츠로", "수업의 몰입도 UP!"],
                                ["음악전문교구", "개인별 제공으로", "수업의 참여도 UP!"],
                                ["워크지로", "한 번 더 정리!", "수업의 이해도 UP!"],
                            ]}
                            color="#e95505"
                            bgcolor="#eeeeef"
                        />
                        <FourImagesCard
                            index={4}
                            titles={["큐레이터", "아트톡톡", "아트플레이", "갤러리"]}
                            desc={[
                                ["서양미술사와", "한국미술사 6개의", "스테이지 클리어!"],
                                ["손가락으로 즐기는", "미술의 조형요소,", "색, 선, 형!"],
                                ["컬러잉, 드로잉,", "숨은그림찾기,", "그림으로 부르는 노래!"],
                                ["화가들의 작품을", "한눈에 볼 수 있는", "온라인 전시회!"],
                            ]}
                            color="#229fe8"
                            bgcolor="#eeeeef"
                        />
                    </Stack>
                </Stack>
            </Stack>
            {/* 특허/밸류 PART */}
            <Stack sx={{ bgcolor: "#eee", position: "relative" }}>
                <Stack display={{ xs: "flex", md: "none" }} alignItems="center" pt={10} px={4}>
                    <img
                        src="/images/jueun-edu/patent.png"
                        alt="특허"
                        style={{ objectFit: "contain", maxWidth: "300px" }}
                    />
                </Stack>
                <Stack
                    sx={{
                        display: { xs: "none", md: "flex" },
                        position: "absolute",
                        top: "50px",
                        left: "calc(50% + 250px)",
                    }}>
                    <img
                        src="/images/jueun-edu/patent.png"
                        alt="특허"
                        style={{ objectFit: "contain", width: "250px" }}
                    />
                </Stack>
                <Stack alignItems="center" pt={6}>
                    {["'재미있고 창의적인 교육문화를", "선도하는 기업 실현'"].map(t => (
                        <Typography
                            key={t}
                            sx={{
                                textAlign: "center",
                                fontFamily: "NanumBrush",
                                fontSize: { xs: "30px", md: "40px" },
                                color: "#2a9013",
                                fontWeight: "500",
                            }}>
                            {t}
                        </Typography>
                    ))}
                </Stack>
                <Stack alignItems="center" py={6}>
                    {[
                        "주은교육은 ‘최초’, ‘최대’라는 수식어를 지켜내고자",
                        "예술교육의 연구·개발과 세계화에 힘쓰며",
                        "‘최고’의 예술교육 기업이 되기 위해 노력하고 있습니다.",
                    ].map(t => (
                        <Typography
                            key={t}
                            sx={{
                                textAlign: "center",
                                fontFamily: "S-CoreDream4",
                                fontSize: { xs: "14px", md: "23px" },
                                color: "#000",
                            }}>
                            {t}
                        </Typography>
                    ))}
                </Stack>
            </Stack>

            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-evenly"
                px={{ xs: 2, lg: 8 }}
                mb={30}
                mt={10}>
                <CoreValue index={1} title="신뢰" desc={["올바른 교육 문화", "선도기업"]} />
                <CoreValue index={2} title="창의" desc={["디지털 에듀테크", "선도기업"]} />
                <CoreValue index={3} title="상생" desc={["학원 경영 시스템의", "세계화"]} />
            </Stack>
        </>
    );
}

function ImageCard({ index, titles, desc }) {
    return (
        <Stack sx={{ width: "100%", height: "100%", position: "relative" }}>
            <img src={`/images/jueun-edu/square${index}.png`} alt={`bg${index}`} />
            <Stack sx={{ position: "absolute", top: { xs: "25%", xl: "20%" }, left: "10%" }}>
                <Typography
                    sx={{
                        color: "#fff",
                        fontFamily: "S-CoreDream6",
                        fontSize: { xs: "20px",sm:"18px", md: "24px", lg: "28px" },
                    }}>
                    {titles[0]}
                </Typography>
                <Typography
                    sx={{
                        color: "#fff",
                        fontFamily: "S-CoreDream6",
                        fontSize: { xs: "20px",sm:"18px", md: "24px", lg: "28px" },
                    }}>
                    {titles[1]}
                </Typography>
                <Typography
                    sx={{
                        color: "#fff",
                        fontFamily: "S-CoreDream6",
                        fontSize: { xs: "14px", md: "16px" },
                        my: { xs: 4, md: 2 },
                    }}>
                    {titles[2]}
                </Typography>
                {desc.map(d => (
                    <Typography
                        key={d}
                        sx={{ color: "#fff", fontFamily: "S-CoreDream3", fontSize: { xs: "14px", md: "16px" } }}>
                        {d}
                    </Typography>
                ))}
            </Stack>
        </Stack>
    );
}

function FourImagesCard({ index, titles, desc, color, bgcolor, textColor }) {
    return (
        <Stack sx={{ position: "relative", p: 2, width: "100%", mb: 4 }}>
            <Stack sx={{ flexDirection: "row", gap: 2 }}>
                {titles.slice(0, 2).map((title, dIndex) => (
                    <OneCard
                        key={title + index}
                        {...{ color, bgcolor, index, dIndex, title, subtitles: desc[dIndex], textColor }}
                    />
                ))}
            </Stack>
            <Stack sx={{ flexDirection: "row", gap: 2, mt: 2 }}>
                {titles.slice(2, 4).map((title, dIndex) => (
                    <OneCard
                        key={title + index}
                        {...{
                            color,
                            bgcolor,
                            index,
                            dIndex: dIndex + 2,
                            title,
                            subtitles: desc[dIndex + 2],
                            textColor,
                        }}
                    />
                ))}
            </Stack>
            <Stack sx={{ position: "absolute", left: "calc(50% - 60px)", top: "calc(50% - 60px)" }}>
                <img
                    src={`/images/jueun-edu/program/logo-${index}.png`}
                    alt={`프로그램-${index}-로고`}
                    style={{ objectFit: "contain", width: "120px", zIndex: 20 }}
                />
            </Stack>
        </Stack>
    );
}

function OneCard({ color, bgcolor, index, dIndex, title, subtitles, textColor }) {
    return (
        <Stack sx={{ width: "100%", alignItems: "center" }}>
            <Stack sx={{ bgcolor: color, minHeight: "180px", borderRadius: 4, zIndex: 3, width: "100%" }}>
                <Typography
                    sx={{
                        fontFamily: "S-CoreDream5",
                        fontSize: "20px",
                        color: textColor ? textColor : "#fff",
                        textAlign: "center",
                        mt: 3,
                        //mb: 2,
                        width: "100%",
                    }}>
                    {title}
                </Typography>
                <Stack sx={{ p: 1, zIndex: 10, height: "106px", alignItems: "center" }}>
                    <img
                        src={`/images/jueun-edu/program/p-${index}-${dIndex + 1}.png`}
                        alt={`프로그램-${index}-${dIndex + 1}`}
                        style={{ objectFit: "contain", width: "178px", height: "106px" }}
                    />
                </Stack>
            </Stack>
            <Stack sx={{ position: "relative", width: "100%", alignItems: "center", zIndex: 2 }}>
                <Stack
                    sx={{
                        position: "absolute",
                        top: "-58px",
                        width: "60px",
                        height: "60px",
                        bgcolor: color,
                        transform: "rotate(45deg)",
                    }}
                />
            </Stack>
            <Stack
                sx={{
                    marginTop: "-50px",
                    bgcolor: bgcolor,
                    width: "100%",
                    height: "170px",
                    borderRadius: 4,
                    zIndex: 1,
                    // border: "1px solid red",
                }}>
                <Stack sx={{ mt: "80px", alignItems: "center" }}>
                    {subtitles.map(subtitle => (
                        <Typography
                            key={subtitle}
                            sx={{
                                textAlign: "center",
                                fontFamily: "S-CoreDream5",
                                fontSize: "16px",
                                color: textColor ? textColor : color,
                            }}>
                            {subtitle}
                        </Typography>
                    ))}
                </Stack>
            </Stack>
        </Stack>
    );
}
function CoreValue({ index, title, desc }) {
    return (
        <Stack sx={{ width: "150px", alignItems: "center" }}>
            <Stack mb={1}>
                <img src={`/images/jueun-edu/corevalue-${index}.png`} alt={title} style={{ objectFit: "contain" }} />
            </Stack>
            <Typography
                sx={{
                    textAlign: "center",
                    fontFamily: "S-CoreDream6",
                    fontSize: { xs: "20px", md: "30px" },
                    color: "#000",
                    mb: 2,
                }}>
                {title}
            </Typography>
            {desc.map(d => (
                <Typography
                    key={d}
                    sx={{
                        textAlign: "center",
                        fontFamily: "S-CoreDream4",
                        fontSize: { xs: "12px", md: "16px" },
                        color: "#000",
                    }}>
                    {d}
                </Typography>
            ))}
        </Stack>
    );
}
