import React from "react";

import { Stack, Typography } from "@mui/material";

import ContainerLayout from "../../layouts/ContainerLayout";
import RoundedButton from "../../elements/RoundedButton";

export default function WayToComeTab() {
    const naverLinkForDeagu = () => {
        const openNewWindow = window.open("about:blank");
        openNewWindow.location.href = `https://naver.me/Gz5DrAS4`;
    };

    const naverLinkForSeoul = () => {
        const openNewWindow = window.open("about:blank");
        openNewWindow.location.href = `https://naver.me/F6meHtQI`;
    };

    const kakaoMapLinkForDeagu = () => {
        const openNewWindow = window.open("about:blank");
        openNewWindow.location.href = `https://place.map.kakao.com/19080043`;
    };

    const kakaoMapLinkForSeoul = () => {
        const openNewWindow = window.open("about:blank");
        openNewWindow.location.href = `https://place.map.kakao.com/1296956107`;
    };

    return (
        <ContainerLayout sx={{ pt: 8, pb: 30 }}>
            <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 6, md: 2 }}>
                <Stack sx={{ width: "100%" }}>
                    <Stack
                        sx={{
                            px: 2.5,
                            py: 3,
                            border: "1px solid",
                            borderColor: "constant.lightGray",
                            borderRadius: "30px",
                        }}>
                        <Typography variant="h3">주은교육 대구 본부</Typography>
                        <Stack direction="row" spacing={2}>
                            <Typography variant="subtitle3" sx={{ lineHeight: 1.43 }}>
                                주소
                            </Typography>
                            <Typography variant="body1" sx={{ span: { display: "block" }, lineHeight: 1.43 }}>
                                <span>대구광역시 달서구 달구벌대로 1130,</span>
                                <span>한길지식산업센터 401호</span>
                            </Typography>
                        </Stack>
                        <Stack direction="row" spacing={2}>
                            <Typography variant="subtitle3" sx={{ lineHeight: 1.43 }}>
                                전화
                            </Typography>
                            <Typography variant="body1" sx={{ lineHeight: 1.43 }}>
                                1688-5884
                            </Typography>
                        </Stack>
                    </Stack>
                    <Stack
                        direction={{ xs: "column", lg: "row" }}
                        spacing={1.5}
                        sx={{ justifyContent: "center", alignItems: "center", mt: 2.5 }}>
                        <RoundedButton onClick={naverLinkForDeagu} size="large" variant="contained" bgcolor="#3cb400">
                            네이버 지도로 찾기
                        </RoundedButton>
                        <RoundedButton
                            onClick={kakaoMapLinkForDeagu}
                            size="large"
                            variant="contained"
                            bgcolor="#fbe102"
                            otherColor="#343434">
                            카카오맵으로 찾기
                        </RoundedButton>
                    </Stack>
                </Stack>
                <Stack sx={{ width: "100%" }}>
                    <Stack
                        sx={{
                            px: 2.5,
                            py: 3,
                            border: "1px solid",
                            borderColor: "constant.lightGray",
                            borderRadius: "30px",
                        }}>
                        <Typography variant="h3">주은교육 서울 본부</Typography>
                        <Stack direction="row" spacing={2}>
                            <Typography variant="subtitle3" sx={{ lineHeight: 1.43 }}>
                                주소
                            </Typography>
                            <Typography variant="body1" sx={{ span: { display: "block" }, lineHeight: 1.43 }}>
                                <span>서울특별시 송파구 동남로 157,</span>
                                <span>GB타워 4층</span>
                            </Typography>
                        </Stack>
                        <Stack direction="row" spacing={2}>
                            <Typography variant="subtitle3" sx={{ lineHeight: 1.43 }}>
                                전화
                            </Typography>
                            <Typography variant="body1" sx={{ lineHeight: 1.43 }}>
                                1688-5884
                            </Typography>
                        </Stack>
                    </Stack>
                    <Stack
                        direction={{ xs: "column", lg: "row" }}
                        spacing={1.5}
                        sx={{ justifyContent: "center", alignItems: "center", mt: 2.5 }}>
                        <RoundedButton onClick={naverLinkForSeoul} size="large" variant="contained" bgcolor="#3cb400">
                            네이버 지도로 찾기
                        </RoundedButton>
                        <RoundedButton
                            onClick={kakaoMapLinkForSeoul}
                            size="large"
                            variant="contained"
                            bgcolor="#fbe102"
                            otherColor="#343434">
                            카카오맵으로 찾기
                        </RoundedButton>
                    </Stack>
                </Stack>
            </Stack>
        </ContainerLayout>
    );
}
